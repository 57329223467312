/* Social network icons */
.icon-twitch:before {
  content: '';
  background-image: url('../img/twitch-grey.svg');
  background-position: center;
  background-size: cover;
  width: 20px;
  height: 20px;
  display: block;
}

.is-dark .icon-twitch:before {
  background-image: url('../img/twitch.svg');
}
.mb-2 {
  margin-bottom: 16px;
}

.icon-snapchat:before {
  content: '';
  background-image: url('../img/snapchat.png');
  background-position: center;
  background-size: cover;
  width: 20px;
  height: 20px;
  display: block;
}

.is-dark .icon-snapchat:before {
  background-image: url('../img/snapchat-dark.png');
}

.icon-tik-tok:before {
  content: '';
  background-image: url('../img/tiktok.svg');
  background-position: center;
  background-size: cover;
  width: 20px;
  height: 20px;
  display: block;
}

.card.card--exp {
  width: 100% !important;
  margin-bottom: 16px;
}

.icon-twitter:before {
  content: '' !important;
  background-image: url('../img/x_twitter-grey.svg');
  background-position: center;
  background-size: cover;
  width: 20px;
  height: 20px;
  display: block;
}

.is-dark .icon-twitter:before {
  background-image: url('../img/x_twitter.svg');
}

.footer__corporate-links .accept-cookie-container {
  color: rgba(255, 255, 255, 0.6);
}

.footer__corporate-links .accept-cookie-container:hover {
  text-decoration: underline;
  color: #fff;
  cursor: pointer;
}

.menu__social {
  line-height: 4;
}

.events.shown::after {
  content: none;
}